<i18n>
  {
    "en": {
      "security": "Security",
      "changePassword": "Change Password"
    },
    "th": {
      "security": "ความปลอดภัย",
      "changePassword": "เปลี่ยนรหัสผ่าน"
    }
  }
</i18n>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-card class="rounded-xl">
          <v-toolbar dense flat class="transparent">
            <h4>{{ $t("settings") }}</h4>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="6">
                  <v-icon left>security</v-icon>{{ $t("security") }}
                </v-col>
                <v-col>
                  <div class="pointer" @click="showChangePassword = true">
                    {{ $t("changePassword") }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ChangePassword
      :show.sync="showChangePassword"
      :user="user"
    ></ChangePassword>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: { ChangePassword: () => import("./ChangePassword.vue") },
  data: () => ({ show: true, showChangePassword: false, user: null }),
  watch: {
    show(val) {
      if (!val) return;
      this.getUser();
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    on() {
      this.show = !this.show;
    },
    async getUser() {
      const { data } = await axios.get("/api/user", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      console.log(data);
      this.user = data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
  color: #3674c9;
}
</style>